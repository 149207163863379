import React, { FC } from "react";
import PopularList from "./popular-list";

export type FooterProps = {
  data?: Array<{ title: string; elements: { path: string; displayName: string }[] }>;
  buttonText: string;
  isWeb: boolean;
  isBgBlack: boolean;
  isRelatedToNlp: boolean;
};
const Footer: FC<FooterProps> = ({
  data = [{ title: "", elements: [] }],
  buttonText = "Tümü",
  isWeb = false,
  isBgBlack = false,
  isRelatedToNlp = false,
}: FooterProps): JSX.Element | null => {
  if (Array.isArray(data) && data.length > 0) {
    let className = "mweb";
    if (isWeb) {
      className = isRelatedToNlp ? "web-nlp" : "web";
    }
    if (!isWeb && data.length === 2 && data[1].title.trim().length === 0) {
      data[0].elements = data[0].elements.filter((e) => e.path !== "" && e.displayName !== "");
      const nonEmptyElements = data[1].elements.filter((e) => e.path !== "" && e.displayName !== "");
      data[0].elements = data[0].elements.concat(nonEmptyElements);
      data.splice(1, 1);
    }
    return (
      <div id={isBgBlack ? "blackbg" : "footer-fragment-context-wrapper"}>
        <div id="footer-fragment-context" data-drroot="int-internal-linking" className={className}>
          <div data-testid="internal-links-footer" className="internal-links-footer">
            {data.map((row: any, index) => (
              <PopularList
                title={row.title.trim().length === 0 ? "\u00A0" : row.title}
                elements={row.elements}
                key={row.title + index}
                index={index}
                buttonText={buttonText}
                isWeb={isWeb}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return <div data-drroot="int-internal-linking"></div>;
};

export default Footer;
