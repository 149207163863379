/* istanbul ignore file */
import "./styles/style.scss";
import ReactDOM from "react-dom";
import * as React from "react";
import {
  WEB_FRAGMENT_FOOTER_NAME,
  MWEB_FRAGMENT_FOOTER_NAME,
  FRAGMENT_NAME,
  MWEB_PARTIAL_FOOTER_NAME,
  WEB_PARTIAL_FOOTER_NAME,
} from "../constant";
import Footer from "./components/footer/footer";

if (window[`__${MWEB_FRAGMENT_FOOTER_NAME}__PROPS` as keyof Window]) {
  ReactDOM.hydrate(
    <Footer {...window[`__${MWEB_FRAGMENT_FOOTER_NAME}__PROPS` as keyof Window]} />,
    document.querySelector(`#${FRAGMENT_NAME}[fragment-partial="${MWEB_PARTIAL_FOOTER_NAME}"]`)
  );
}

if (window[`__${WEB_FRAGMENT_FOOTER_NAME}__PROPS` as keyof Window]) {
  ReactDOM.hydrate(
    <Footer {...window[`__${WEB_FRAGMENT_FOOTER_NAME}__PROPS` as keyof Window]} />,
    document.querySelector(`#${FRAGMENT_NAME}[fragment-partial="${WEB_PARTIAL_FOOTER_NAME}"]`)
  );
}

// for debug on preprod
if ((window as any).dispatchDREvent) {
  (window as any).dispatchDREvent({ key: "int-internal-linking", type: "done" });
}
